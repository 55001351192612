import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export default function (props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Portfolio"
          source="portfolio"
          reference="portfolios"
          filter={{ status: 1 }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Asset"
          source="asset"
          reference="assets"
          filter={{ status: 1 }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
