import { FilterList, FilterListItem } from 'react-admin';

import { AccountTypes } from './constants';

export const AccountTypeFilter = () => (
  <FilterList label="Account type">
    {AccountTypes.map(item => (
      <FilterListItem label={item.name} value={{ account_type: item.id }} />
    ))}
  </FilterList>
);

export const IsEnableFilter = () => (
  <FilterList label="Is enable">
    <FilterListItem label="True" value={{ is_enable: true }} />
    <FilterListItem label="False" value={{ is_enable: false }} />
  </FilterList>
);

export const IsTransferableFilter = () => (
  <FilterList label="Is transferable">
    <FilterListItem label="True" value={{ is_transferable: true }} />
    <FilterListItem label="False" value={{ is_transferable: false }} />
  </FilterList>
);

export const IsWithdrawableFilter = () => (
  <FilterList label="Is withdrawable">
    <FilterListItem label="True" value={{ is_withdrawable: true }} />
    <FilterListItem label="False" value={{ is_withdrawable: false }} />
  </FilterList>
);
