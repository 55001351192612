import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { BankStatus } from './constants';

export default function BankEdit(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<AppToolbar />}>
        <TextInput disabled source="id" />
        <TextInput disabled source="account_name" />
        <TextInput disabled source="account_number" />
        <TextInput disabled source="bank_name" />
        <TextInput disabled source="bank_country" />
        <TextInput disabled source="bank_region" />
        <TextInput disabled source="bank_branch" />
        <TextInput disabled source="username" label="Member no." />
        <ReferenceInput
          label="Member Last name"
          disabled
          source="user_id"
          reference="users"
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="last_name" />
        </ReferenceInput>
        <ReferenceInput
          label="Member first name"
          disabled
          source="user_id"
          reference="users"
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="first_name" />
        </ReferenceInput>
        <TextInput source="remark" multiline fullWidth />
        <SelectInput source="bank_status" choices={BankStatus} />
      </SimpleForm>
    </Edit>
  );
}
