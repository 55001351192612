import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <NumberInput source="rate" />
        <ReferenceInput
          disabled
          source="term"
          reference="terms"
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="days" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
