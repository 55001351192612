import { useCallback } from 'react';

import { AutocompleteInput } from 'react-admin';

import { AccountTypes } from '../accounts/constants';

export const AccountAutocompleteInput = ({ record, ...props }) => {
  const getAccountTypeName = account_type => {
    const accountType = AccountTypes.find(item => item.id === account_type);
    return accountType ? accountType.name : '';
  };

  const getOptionText = useCallback(option => {
    return `${option?.username} (${getAccountTypeName(option?.account_type)})`;
  }, []);

  return <AutocompleteInput optionText={getOptionText} {...props} />;
};
