import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function ReferralEdit(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false} toolbar={<AppToolbar />}>
        <TextInput disabled source="id" />
        <ReferenceInput
          label="Referrer"
          source="referrer"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="Referred"
          source="referred"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
          disabled
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="Referred"
          source="referred"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
          disabled
        >
          <AutocompleteInput optionText="last_name" />
        </ReferenceInput>
        <ReferenceInput
          label="Referred"
          source="referred"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
          disabled
        >
          <AutocompleteInput optionText="first_name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
