import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { TransactionStatus } from '../transactions/constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="account_name" />
        <TextInput disabled source="account_number" />
        <TextInput disabled source="bank_name" />
        <TextInput disabled source="bank_country" />
        <TextInput disabled source="bank_region" />
        <TextInput disabled source="bank_branch" />
        <DateInput disabled source="withdrawal_date" />
        <TextInput
          disabled
          source="account_details.account_type_display"
          label="Account"
        />
        <NumberInput
          disabled
          source="withdrawal_transaction.amount"
          label="Amount"
        />
        <SelectInput
          source="withdrawal_transaction.transaction_status"
          choices={TransactionStatus}
          label="Withdrawal status"
        />
        <TextInput
          source="withdrawal_transaction.remark"
          label="Remark"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
}
