import { List, Datagrid, TextField, EditButton } from 'react-admin';

import { AppPagination, AppExporter, SearchFilter } from '../../components';

export default function (props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="user_username" />
        <TextField source="tier_title" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
