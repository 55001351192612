import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  SelectField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppPagination, AppExporter, SearchFilter } from '../../components';

import { WalletStatus, WalletType } from './constants';
import { WalletStatusFilter, WalletTypeFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <WalletTypeFilter />
      <WalletStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="wallet_address" />
        <SelectField source="wallet_status" choices={WalletStatus} />
        <SelectField source="wallet_type" choices={WalletType} />
        <TextField source="user_username" />
        <DateField source="date_created" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
