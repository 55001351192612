import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AccountTypeFilter } from '../accounts/filters';

import { AppExporter, SearchFilter, AppPagination } from '../../components';
import { TransactionStatusFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <AccountTypeFilter />
      <TransactionStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function TransactionList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="user_username" label="Member no." />
        <TextField source="account_type_display" label="Account" />
        <NumberField source="amount" />
        <DateField source="transaction_date" showTime />
        <TextField source="transaction_status_display" />
        <TextField source="transaction_type_display" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
