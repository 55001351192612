import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';

import { AppToolbar } from '../../components';

import { Status } from './constants';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="referrer" />
        <TextInput disabled source="referrer_username" />
        <TextInput disabled source="balance" />
        <TextInput disabled source="rate" />
        <TextInput disabled source="amount" />
        <TextInput disabled source="referred_id" />
        <TextInput disabled source="referred_username" />
        <SelectInput source="status" choices={Status} />
      </SimpleForm>
    </Edit>
  );
}
