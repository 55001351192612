import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin';

import { AppPagination, AppExporter, SearchFilter } from '../../components';

export default function (props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="portfolio_title" />
        <TextField source="asset_code" />
        <TextField source="asset_name" />
        <DateField source="date_created" showTime />
        <DateField source="date_updated" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
