import { Edit, SimpleForm, NumberInput, BooleanInput } from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <NumberInput source="days" />
        <BooleanInput source="is_enable" />
      </SimpleForm>
    </Edit>
  );
}
