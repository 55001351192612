import { FilterList, FilterListItem } from 'react-admin';

import { UserStatus, IsActive } from './constants';

export const StatusFilter = () => (
  <FilterList label="Status">
    {UserStatus.map(item => (
      <FilterListItem label={item.name} value={{ status: item.id }} />
    ))}
  </FilterList>
);

export const IsActiveFilter = () => (
  <FilterList label="Is deactivated">
    {IsActive.map(item => (
      <FilterListItem label={item.name} value={{ is_active: item.id }} />
    ))}
  </FilterList>
);
