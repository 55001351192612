import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput source="id" disabled />
        <ReferenceInput
          label="User"
          source="user"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
          disabled
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="Tier"
          source="tier"
          reference="tiers"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
