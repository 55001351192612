import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { Status } from './constants';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="user" />
        <TextInput disabled source="user_username" />
        {/* <TextInput disabled source="term_value" /> */}
        <DateTimeInput disabled source="start_date" />
        <DateTimeInput disabled source="end_date" />
        <NumberInput disabled source="unit" />
        <NumberInput disabled source="amount" />
        <NumberInput disabled source="shares" />
        <NumberInput disabled source="total" />
        <BooleanInput source="is_company" />
        <NumberInput source="company_unit" />
        <NumberInput source="company_amount" />
        <SelectInput source="status" choices={Status} />
        {/* <ReferenceInput
          disabled
          source="term"
          reference="terms"
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="description" />
        </ReferenceInput> */}
        <ReferenceInput
          disabled
          source="portfolio"
          reference="portfolios"
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
