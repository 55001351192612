import { FilterList, FilterListItem } from 'react-admin';

import { Status } from './constants';

export const StatusFilter = () => (
  <FilterList label="Status">
    {Status.map(item => (
      <FilterListItem label={item.name} value={{ status: item.id }} />
    ))}
  </FilterList>
);
