import React from 'react';
import { Fragment } from 'react';

import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const IsTransferable = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'accounts',
    props.selectedIds,
    {
      is_transferable: props.val,
    },
    {
      onSuccess: () => {
        refresh();
        notify('Records updated');
        unselectAll('accounts');
      },
      onFailure: error => notify('Error: records not updated', 'warning'),
    }
  );

  return <Button label={props.label} disabled={loading} onClick={updateMany} />;
};

export const AccountBulkActionButtons = props => (
  <Fragment>
    <IsTransferable {...props} label="Disable transfer" val={0} />
    <IsTransferable {...props} label="Enable transfer" val={1} />
  </Fragment>
);
