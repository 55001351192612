import { Admin, fetchUtils, Resource } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

import { authProvider, drfProvider } from './providers';

import users from './sections/users';
import accounts from './sections/accounts';
import transactions from './sections/transactions';
import blockchainWallets from './sections/blockchainWallets';
import banks from './sections/banks';
import referrals from './sections/referrals';
import referralBonus from './sections/referralBonus';
import referralBonusSettings from './sections/referralBonusSettings';
import passwords from './sections/passwords';
import withdrawalBanks from './sections/withdrawalBanks';
import withdrawalBlockchains from './sections/withdrawalBlockchains';
import withdrawalSettings from './sections/withdrawalSettings';
import assets from './sections/assets';
import portfolios from './sections/portfolios';
import portfolioAssets from './sections/portfolioAssets';
import tiers from './sections/tiers';
import tierUsers from './sections/tierUsers';
import terms from './sections/terms';
import contracts from './sections/contracts';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D9010B',
    },
    secondary: {
      main: '#fe132a',
    },
  },
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default function App() {
  return (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={drfProvider(process.env.REACT_APP_API_HOST, httpClient)}
    >
      <Resource name="accounts" {...accounts} />
      <Resource name="users/switch" edit={users.edit} />
      <Resource name="users" {...users} />
      <Resource name="passwords" {...passwords} />
      <Resource name="transactions" {...transactions} />
      <Resource
        name="blockchain/wallets"
        {...blockchainWallets}
        options={{ label: 'Blockchain wallets' }}
      />
      <Resource name="banks" {...banks} />
      <Resource
        name="withdrawals/settings"
        {...withdrawalSettings}
        options={{ label: 'Withdrawal settings' }}
      />
      <Resource
        name="withdrawals/banks"
        {...withdrawalBanks}
        options={{ label: 'Withdrawal banks' }}
      />
      <Resource
        name="withdrawals/blockchains"
        {...withdrawalBlockchains}
        options={{ label: 'Withdrawal blockchains' }}
      />
      <Resource name="portfolios/assets" {...portfolioAssets} />
      <Resource name="portfolios" {...portfolios} />
      <Resource name="assets" {...assets} />
      <Resource name="terms" {...terms} />
      <Resource
        name="tiers/users"
        {...tierUsers}
        options={{ label: 'Tier user' }}
      />
      <Resource name="tiers" {...tiers} />
      <Resource name="contracts" {...contracts} />
      <Resource
        name="referrals/bonuses/settings"
        {...referralBonusSettings}
        options={{ label: 'Referral settings' }}
      />
      <Resource
        name="referrals/bonuses"
        {...referralBonus}
        options={{ label: 'Referral bonus' }}
      />
      <Resource name="referrals" {...referrals} />
    </Admin>
  );
}
