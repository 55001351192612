import { FilterList, FilterListItem } from 'react-admin';

import { TransactionStatus } from '../transactions/constants';

export const WithdrawalStatusFilter = () => (
  <FilterList label="Withdrawal status">
    {TransactionStatus.map(item => (
      <FilterListItem
        label={item.name}
        value={{ withdrawal_status: item.id }}
      />
    ))}
  </FilterList>
);
