import axios from 'axios';
// import inMemoryJWTManager from './jwtManager';

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    return axios
      .post(process.env.REACT_APP_API_HOST + '/token/', {
        username,
        password,
      })
      .then(res => {
        // inMemoryJWTManager.setToken(res.data.access);
        localStorage.setItem('token', res.data.access);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    // inMemoryJWTManager.eraseToken();
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      // inMemoryJWTManager.eraseToken();
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    // return inMemoryJWTManager.getToken()
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
