import { FilterList, FilterListItem } from 'react-admin';

import { BankStatus } from './constants';

export const BankStatusFilter = () => (
  <FilterList label="Bank status">
    {BankStatus.map(item => (
      <FilterListItem label={item.name} value={{ bank_status: item.id }} />
    ))}
  </FilterList>
);
