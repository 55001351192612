import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export default function ReferralCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Referrer"
          source="referrer"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
          label="Referred"
          source="referred"
          reference="users"
          filter={{ is_active: true }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
