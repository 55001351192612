import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';

import { AppToolbar } from '../../components';

import { Status } from './constants';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput source="title" />
        <TextInput source="desc" />
        <SelectInput source="status" choices={Status} />
      </SimpleForm>
    </Edit>
  );
}
