import {
  Create,
  SimpleForm,
  ReferenceInput,
  NumberInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import { TransactionStatus, TransactionTypes } from './constants';
import { AccountAutocompleteInput } from './components';

export default function TransactionCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Account"
          source="account"
          reference="accounts"
          filter={{ is_active: true, searchFields: 'username' }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AccountAutocompleteInput />
        </ReferenceInput>
        <NumberInput source="amount" />
        <SelectInput
          disabled={true}
          source="transaction_type"
          choices={TransactionTypes}
          defaultValue={1}
        />
        <SelectInput source="transaction_status" choices={TransactionStatus} />
        <TextInput source="remark" multiline fullWidth />
      </SimpleForm>
    </Create>
  );
}
