import React from 'react';
import { Filter, TextInput } from 'react-admin';

export default function SearchFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="search" source="search" alwaysOn />
    </Filter>
  );
}
