import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect={false}>
        <ReferenceInput
          label="Portfolio"
          source="portfolio"
          reference="portfolios"
          filter={{ status: 1 }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          label="Asset"
          source="asset"
          reference="assets"
          filter={{ status: 1 }}
          sort={{ field: 'id', order: 'ASC' }}
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
