export const WalletStatus = [
  { id: 1, name: 'pending' },
  { id: 2, name: 'authorized' },
  { id: 3, name: 'deactivated' },
  { id: 4, name: 'rejected' },
];

export const WalletType = [
  { id: 1, name: 'deposit' },
  { id: 2, name: 'withdrawal' },
];
