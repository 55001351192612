import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SelectField,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

import { Status } from './constants';
import { StatusFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <StatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="referrer" />
        <TextField source="referrer_username" />
        <TextField source="balance" label="amount" />
        <TextField source="rate" />
        <TextField source="amount" label="bonus" />
        <TextField source="referred" />
        <TextField source="referred_username" />
        <SelectField source="status" choices={Status} />
        <DateField source="date_created" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
