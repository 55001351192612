import { Create, NumberInput, SimpleForm } from 'react-admin';

export default function (props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="days" />
      </SimpleForm>
    </Create>
  );
}
