import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { AssetStatus, AssetType } from './constants';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput source="code" />
        <TextInput source="name" />
        <TextInput source="currency" />
        <NumberInput source="price" defaultValue={0} />
        <NumberInput
          source="price_adj_rate"
          defaultValue={0}
          label="Price adjust rate"
        />
        <SelectInput source="status" choices={AssetStatus} />
        <SelectInput source="asset_type" choices={AssetType} />
      </SimpleForm>
    </Edit>
  );
}
