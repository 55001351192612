import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

export default function AppExporter(records) {
  const dataForExport = records.map(record => {
    const { ...recordToExport } = record;
    return recordToExport;
  });
  jsonExport(dataForExport, (err, csv) => {
    const BOM = '\uFEFF';
    downloadCSV(`${BOM} ${csv}`, 'export'); // download as 'posts.csv` file
  });
}
