import { FilterList, FilterListItem } from 'react-admin';

import { Status } from './constants';

export const StatusFilter = () => (
  <FilterList label="Status">
    {Status.map(item => (
      <FilterListItem label={item.name} value={{ status: item.id }} />
    ))}
  </FilterList>
);

export const IsCompanyFilter = () => (
  <FilterList label="Is Company">
    <FilterListItem label="True" value={{ is_company: true }} />
    <FilterListItem label="False" value={{ is_company: false }} />
  </FilterList>
);
