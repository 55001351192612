import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

export default function (props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="code" />
        <TextInput source="name" />
        <TextInput source="currency" />
        <NumberInput source="price" defaultValue={0} />
        <NumberInput
          source="price_adj_rate"
          defaultValue={0}
          label="Price adjust rate"
        />
      </SimpleForm>
    </Create>
  );
}
