import { FilterList, FilterListItem } from 'react-admin';

import { AssetStatus, AssetType } from './constants';

export const AssetStatusFilter = () => (
  <FilterList label="Asset status">
    {AssetStatus.map(item => (
      <FilterListItem label={item.name} value={{ status: item.id }} />
    ))}
  </FilterList>
);

export const AssetTypeFilter = () => (
  <FilterList label="Asset type">
    {AssetType.map(item => (
      <FilterListItem label={item.name} value={{ asset_type: item.id }} />
    ))}
  </FilterList>
);
