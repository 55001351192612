import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageField,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { WalletStatus, WalletType } from './constants';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="wallet_address" />
        <SelectInput disabled source="wallet_type" choices={WalletType} />
        <TextInput source="remark" multiline fullWidth />
        <SelectInput source="wallet_status" choices={WalletStatus} />
        <ImageField source="attachment.url" />
      </SimpleForm>
    </Edit>
  );
}
