import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  SelectField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppPagination, AppExporter, SearchFilter } from '../../components';

import { AssetStatus, AssetType } from './constants';
import { AssetStatusFilter, AssetTypeFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <AssetStatusFilter />
      <AssetTypeFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="currency" />
        <SelectField source="status" choices={AssetStatus} />
        <SelectField source="asset_type" choices={AssetType} />
        <NumberField source="price" />
        <NumberField source="price_adj_rate" />
        <DateField source="date_created" showTime />
        <DateField source="date_updated" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
}
