import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  NumberField,
} from 'react-admin';

import { AppPagination, AppExporter, SearchFilter } from '../../components';

export default function (props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <NumberField source="days" />
        <BooleanField source="is_enable" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
