import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { AccountTypes } from './constants';

export default function AccountEdit(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="username" />
        <SelectInput disabled source="account_type" choices={AccountTypes} />
        <BooleanInput source="is_transferable" />
        <BooleanInput source="is_withdrawable" />
        <BooleanInput source="is_enable" />
        <DateTimeInput disabled source="date_created" />
      </SimpleForm>
    </Edit>
  );
}
