import React from 'react';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';

export default function AppToolbar(props) {
  const { enableDelete } = props;

  return (
    <Toolbar {...props}>
      <SaveButton />
      {enableDelete ? <DeleteButton /> : <></>}
    </Toolbar>
  );
}
