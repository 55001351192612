import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  NumberField,
  SelectField,
  ReferenceField,
  BooleanField,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

import { Status } from './constants';
import { StatusFilter, IsCompanyFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <StatusFilter />
      <IsCompanyFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="user_username" />
        <DateField source="start_date" showTime={true} />
        <DateField source="end_date" showTime={true} />
        <NumberField source="unit" />
        <NumberField source="amount" />
        <NumberField source="shares" />
        <NumberField source="total" />
        <SelectField source="status" choices={Status} />
        <BooleanField source="is_company" />
        {/* <ReferenceField
          source="term_tier"
          reference="terms"
          sortBy="id"
          link={false}
        >
          <TextField source="days" />
        </ReferenceField> */}
        <ReferenceField
          source="portfolio"
          reference="portfolios"
          sortBy="id"
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
}
