import { Edit, SimpleForm, TextInput, PasswordInput } from 'react-admin';

import { AppToolbar } from '../../components';

const redirect = (basePath, id, data) => `/users/${id}`;

export default function PasswordEdit(props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={redirect}>
        <TextInput disabled source="id" />
        <TextInput disabled source="username" />
        <PasswordInput source="password" label="Password" />
        <PasswordInput
          source="transaction_password"
          label="Transaction Password"
        />
      </SimpleForm>
    </Edit>
  );
}
