export const UserStatus = [
  { id: 1, name: 'pending' },
  { id: 2, name: 'verified' },
  { id: 3, name: 'rejected' },
  { id: 4, name: 'reverted' },
];

export const IsActive = [
  { id: true, name: 'active' },
  { id: false, name: 'deactivated' },
];
