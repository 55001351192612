import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput source="title" />
        <NumberInput source="minimum_unit" />
        <NumberInput source="share_limit_rate" />
        <NumberInput source="contract_share_payout_rate" />
        <NumberInput source="withdrawal_limit_rate" />
        <BooleanInput source="is_withdrawable" />
        <BooleanInput source="allow_stake" />
        <BooleanInput source="allow_multiple_contract" />
        <BooleanInput source="is_enable" />
      </SimpleForm>
    </Edit>
  );
}
