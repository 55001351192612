import { FilterList, FilterListItem } from 'react-admin';

import { WalletStatus, WalletType } from './constants';

export const WalletStatusFilter = () => (
  <FilterList label="Status">
    {WalletStatus.map(item => (
      <FilterListItem label={item.name} value={{ status: item.id }} />
    ))}
  </FilterList>
);

export const WalletTypeFilter = () => (
  <FilterList label="Type">
    {WalletType.map(item => (
      <FilterListItem label={item.name} value={{ wallet_type: item.id }} />
    ))}
  </FilterList>
);
